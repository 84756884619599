/* eslint-disable */ 
// source: vseth/sip/payment/payment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var vseth_type_money_pb = require('../../../vseth/type/money_pb.js');
goog.object.extend(proto, vseth_type_money_pb);
goog.exportSymbol('proto.vseth.sip.payment.CreateOrderRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.CreatePaymentGatewayRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.GetOrderRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.GetPaymentGatewayRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.ListOrdersRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.ListOrdersResponse', null, global);
goog.exportSymbol('proto.vseth.sip.payment.ListPaymentGatewaysRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.ListPaymentGatewaysResponse', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.Filter', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.Filter.DateRange', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.Filter.DateRange.FromCase', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.Filter.DateRange.UntilCase', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.Filter.SortBy', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.Status', null, global);
goog.exportSymbol('proto.vseth.sip.payment.Order.View', null, global);
goog.exportSymbol('proto.vseth.sip.payment.OrderItem', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway.Filter', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway.Filter.DateRange', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.FromCase', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.UntilCase', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway.Filter.SortBy', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentGateway.Status', null, global);
goog.exportSymbol('proto.vseth.sip.payment.PaymentMethod', null, global);
goog.exportSymbol('proto.vseth.sip.payment.RefundOrderItemsRequest', null, global);
goog.exportSymbol('proto.vseth.sip.payment.RefundOrderItemsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.payment.Order.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.payment.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.Order.displayName = 'proto.vseth.sip.payment.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.Order.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.payment.Order.Filter.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.payment.Order.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.Order.Filter.displayName = 'proto.vseth.sip.payment.Order.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.Order.Filter.DateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_);
};
goog.inherits(proto.vseth.sip.payment.Order.Filter.DateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.Order.Filter.DateRange.displayName = 'proto.vseth.sip.payment.Order.Filter.DateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.OrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.OrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.OrderItem.displayName = 'proto.vseth.sip.payment.OrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.ListOrdersRequest.displayName = 'proto.vseth.sip.payment.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.ListOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.payment.ListOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.payment.ListOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.ListOrdersResponse.displayName = 'proto.vseth.sip.payment.ListOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.CreateOrderRequest.displayName = 'proto.vseth.sip.payment.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.GetOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.GetOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.GetOrderRequest.displayName = 'proto.vseth.sip.payment.GetOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.RefundOrderItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.payment.RefundOrderItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.payment.RefundOrderItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.RefundOrderItemsRequest.displayName = 'proto.vseth.sip.payment.RefundOrderItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.RefundOrderItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.payment.RefundOrderItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.payment.RefundOrderItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.RefundOrderItemsResponse.displayName = 'proto.vseth.sip.payment.RefundOrderItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.PaymentGateway = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.PaymentGateway, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.PaymentGateway.displayName = 'proto.vseth.sip.payment.PaymentGateway';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.PaymentGateway.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.PaymentGateway.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.PaymentGateway.Filter.displayName = 'proto.vseth.sip.payment.PaymentGateway.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_);
};
goog.inherits(proto.vseth.sip.payment.PaymentGateway.Filter.DateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.displayName = 'proto.vseth.sip.payment.PaymentGateway.Filter.DateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.ListPaymentGatewaysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.ListPaymentGatewaysRequest.displayName = 'proto.vseth.sip.payment.ListPaymentGatewaysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vseth.sip.payment.ListPaymentGatewaysResponse.repeatedFields_, null);
};
goog.inherits(proto.vseth.sip.payment.ListPaymentGatewaysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.ListPaymentGatewaysResponse.displayName = 'proto.vseth.sip.payment.ListPaymentGatewaysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.CreatePaymentGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.CreatePaymentGatewayRequest.displayName = 'proto.vseth.sip.payment.CreatePaymentGatewayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vseth.sip.payment.GetPaymentGatewayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vseth.sip.payment.GetPaymentGatewayRequest.displayName = 'proto.vseth.sip.payment.GetPaymentGatewayRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.payment.Order.repeatedFields_ = [13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    booked: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expireTime: (f = msg.getExpireTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paidTime: (f = msg.getPaidTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    initiator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 9, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 10, ""),
    orderUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    callbackUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    receiptUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.vseth.sip.payment.OrderItem.toObject, includeInstance),
    paymentMethodsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    total: (f = msg.getTotal()) && vseth_type_money_pb.Money.toObject(includeInstance, f),
    view: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.Order}
 */
proto.vseth.sip.payment.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.Order;
  return proto.vseth.sip.payment.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.Order}
 */
proto.vseth.sip.payment.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.vseth.sip.payment.Order.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBooked(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaidTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiator(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderUrl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallbackUrl(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptUrl(value);
      break;
    case 13:
      var value = new proto.vseth.sip.payment.OrderItem;
      reader.readMessage(value,proto.vseth.sip.payment.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 14:
      var values = /** @type {!Array<!proto.vseth.sip.payment.PaymentMethod>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPaymentMethods(values[i]);
      }
      break;
    case 15:
      var value = new vseth_type_money_pb.Money;
      reader.readMessage(value,vseth_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 16:
      var value = /** @type {!proto.vseth.sip.payment.Order.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBooked();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpireTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaidTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInitiator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOrderUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCallbackUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReceiptUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.vseth.sip.payment.OrderItem.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      14,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vseth_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vseth.sip.payment.Order.Status = {
  STATUS_UNSPECIFIED: 0,
  CREATED: 1,
  CLAIMED: 2,
  OPEN: 3,
  PAID: 4,
  EXPIRED: 5,
  FAILED: 6
};

/**
 * @enum {number}
 */
proto.vseth.sip.payment.Order.View = {
  BASIC: 0,
  FULL: 1,
  WITH_ITEMS: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.payment.Order.Filter.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.Order.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.Order.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.Order.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.Order.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createTime: (f = msg.getCreateTime()) && proto.vseth.sip.payment.Order.Filter.DateRange.toObject(includeInstance, f),
    expireTime: (f = msg.getExpireTime()) && proto.vseth.sip.payment.Order.Filter.DateRange.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && proto.vseth.sip.payment.Order.Filter.DateRange.toObject(includeInstance, f),
    paidTime: (f = msg.getPaidTime()) && proto.vseth.sip.payment.Order.Filter.DateRange.toObject(includeInstance, f),
    creator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 9, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 10, ""),
    productsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    sortBy: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.Order.Filter}
 */
proto.vseth.sip.payment.Order.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.Order.Filter;
  return proto.vseth.sip.payment.Order.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.Order.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.Order.Filter}
 */
proto.vseth.sip.payment.Order.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.vseth.sip.payment.Order.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.vseth.sip.payment.Order.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 5:
      var value = new proto.vseth.sip.payment.Order.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinaryFromReader);
      msg.setExpireTime(value);
      break;
    case 6:
      var value = new proto.vseth.sip.payment.Order.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 7:
      var value = new proto.vseth.sip.payment.Order.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinaryFromReader);
      msg.setPaidTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addProducts(value);
      break;
    case 12:
      var value = /** @type {!proto.vseth.sip.payment.Order.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.Order.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.Order.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.Order.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.Order.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vseth.sip.payment.Order.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getExpireTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vseth.sip.payment.Order.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vseth.sip.payment.Order.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getPaidTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vseth.sip.payment.Order.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vseth.sip.payment.Order.Filter.SortBy = {
  NAME: 0,
  NUMBER: 1,
  STATUS: 2,
  CREATED_TIME: 3,
  EXPIRE_TIME: 4,
  UPDATE_TIME: 5,
  PAID_TIME: 6,
  CREATOR: 7,
  OPERATOR: 8,
  CUSTOMER: 9
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_ = [[1,2],[3,4]];

/**
 * @enum {number}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.FromCase = {
  FROM_NOT_SET: 0,
  UNLIMITED_FROM_RANGE: 1,
  TIMESTAMP_FROM: 2
};

/**
 * @return {proto.vseth.sip.payment.Order.Filter.DateRange.FromCase}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.getFromCase = function() {
  return /** @type {proto.vseth.sip.payment.Order.Filter.DateRange.FromCase} */(jspb.Message.computeOneofCase(this, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.UntilCase = {
  UNTIL_NOT_SET: 0,
  UNLIMITED_UNTIL_RANGE: 3,
  TIMESTAMP_UNTIL: 4
};

/**
 * @return {proto.vseth.sip.payment.Order.Filter.DateRange.UntilCase}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.getUntilCase = function() {
  return /** @type {proto.vseth.sip.payment.Order.Filter.DateRange.UntilCase} */(jspb.Message.computeOneofCase(this, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.Order.Filter.DateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.Order.Filter.DateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.Order.Filter.DateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    unlimitedFromRange: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    timestampFrom: (f = msg.getTimestampFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unlimitedUntilRange: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    timestampUntil: (f = msg.getTimestampUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.Order.Filter.DateRange;
  return proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.Order.Filter.DateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlimitedFromRange(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampFrom(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlimitedUntilRange(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.Order.Filter.DateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.Order.Filter.DateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.Order.Filter.DateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTimestampFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTimestampUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool unlimited_from_range = 1;
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.getUnlimitedFromRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.setUnlimitedFromRange = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.clearUnlimitedFromRange = function() {
  return jspb.Message.setOneofField(this, 1, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.hasUnlimitedFromRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.getTimestampFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
*/
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.setTimestampFrom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.clearTimestampFrom = function() {
  return this.setTimestampFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.hasTimestampFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool unlimited_until_range = 3;
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.getUnlimitedUntilRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.setUnlimitedUntilRange = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.clearUnlimitedUntilRange = function() {
  return jspb.Message.setOneofField(this, 3, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.hasUnlimitedUntilRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.getTimestampUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
*/
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.setTimestampUntil = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.vseth.sip.payment.Order.Filter.DateRange.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.clearTimestampUntil = function() {
  return this.setTimestampUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.DateRange.prototype.hasTimestampUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.vseth.sip.payment.Order.Status}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.payment.Order.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vseth.sip.payment.Order.Status} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DateRange create_time = 4;
 * @return {?proto.vseth.sip.payment.Order.Filter.DateRange}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getCreateTime = function() {
  return /** @type{?proto.vseth.sip.payment.Order.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.Order.Filter.DateRange, 4));
};


/**
 * @param {?proto.vseth.sip.payment.Order.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
*/
proto.vseth.sip.payment.Order.Filter.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DateRange expire_time = 5;
 * @return {?proto.vseth.sip.payment.Order.Filter.DateRange}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getExpireTime = function() {
  return /** @type{?proto.vseth.sip.payment.Order.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.Order.Filter.DateRange, 5));
};


/**
 * @param {?proto.vseth.sip.payment.Order.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
*/
proto.vseth.sip.payment.Order.Filter.prototype.setExpireTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.clearExpireTime = function() {
  return this.setExpireTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.prototype.hasExpireTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DateRange update_time = 6;
 * @return {?proto.vseth.sip.payment.Order.Filter.DateRange}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getUpdateTime = function() {
  return /** @type{?proto.vseth.sip.payment.Order.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.Order.Filter.DateRange, 6));
};


/**
 * @param {?proto.vseth.sip.payment.Order.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
*/
proto.vseth.sip.payment.Order.Filter.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DateRange paid_time = 7;
 * @return {?proto.vseth.sip.payment.Order.Filter.DateRange}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getPaidTime = function() {
  return /** @type{?proto.vseth.sip.payment.Order.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.Order.Filter.DateRange, 7));
};


/**
 * @param {?proto.vseth.sip.payment.Order.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
*/
proto.vseth.sip.payment.Order.Filter.prototype.setPaidTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.clearPaidTime = function() {
  return this.setPaidTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.Filter.prototype.hasPaidTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string creator = 8;
 * @return {string}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string operator = 9;
 * @return {string}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string customer = 10;
 * @return {string}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string products = 11;
 * @return {!Array<string>}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getProductsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setProductsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.addProducts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional SortBy sort_by = 12;
 * @return {!proto.vseth.sip.payment.Order.Filter.SortBy}
 */
proto.vseth.sip.payment.Order.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.vseth.sip.payment.Order.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.vseth.sip.payment.Order.Filter.SortBy} value
 * @return {!proto.vseth.sip.payment.Order.Filter} returns this
 */
proto.vseth.sip.payment.Order.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.vseth.sip.payment.Order.Status}
 */
proto.vseth.sip.payment.Order.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.payment.Order.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vseth.sip.payment.Order.Status} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool booked = 17;
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.prototype.getBooked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setBooked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.Order.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.Order} returns this
*/
proto.vseth.sip.payment.Order.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expire_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.Order.prototype.getExpireTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.Order} returns this
*/
proto.vseth.sip.payment.Order.prototype.setExpireTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearExpireTime = function() {
  return this.setExpireTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.prototype.hasExpireTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.Order.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.Order} returns this
*/
proto.vseth.sip.payment.Order.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp paid_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.Order.prototype.getPaidTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.Order} returns this
*/
proto.vseth.sip.payment.Order.prototype.setPaidTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearPaidTime = function() {
  return this.setPaidTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.prototype.hasPaidTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string initiator = 8;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getInitiator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setInitiator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string operator = 9;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string customer = 10;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string order_url = 11;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getOrderUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setOrderUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string callback_url = 12;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getCallbackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setCallbackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string receipt_url = 18;
 * @return {string}
 */
proto.vseth.sip.payment.Order.prototype.getReceiptUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setReceiptUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated OrderItem items = 13;
 * @return {!Array<!proto.vseth.sip.payment.OrderItem>}
 */
proto.vseth.sip.payment.Order.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.vseth.sip.payment.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.payment.OrderItem, 13));
};


/**
 * @param {!Array<!proto.vseth.sip.payment.OrderItem>} value
 * @return {!proto.vseth.sip.payment.Order} returns this
*/
proto.vseth.sip.payment.Order.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vseth.sip.payment.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.OrderItem}
 */
proto.vseth.sip.payment.Order.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vseth.sip.payment.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated PaymentMethod payment_methods = 14;
 * @return {!Array<!proto.vseth.sip.payment.PaymentMethod>}
 */
proto.vseth.sip.payment.Order.prototype.getPaymentMethodsList = function() {
  return /** @type {!Array<!proto.vseth.sip.payment.PaymentMethod>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<!proto.vseth.sip.payment.PaymentMethod>} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setPaymentMethodsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {!proto.vseth.sip.payment.PaymentMethod} value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.addPaymentMethods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearPaymentMethodsList = function() {
  return this.setPaymentMethodsList([]);
};


/**
 * optional vseth.type.Money total = 15;
 * @return {?proto.vseth.type.Money}
 */
proto.vseth.sip.payment.Order.prototype.getTotal = function() {
  return /** @type{?proto.vseth.type.Money} */ (
    jspb.Message.getWrapperField(this, vseth_type_money_pb.Money, 15));
};


/**
 * @param {?proto.vseth.type.Money|undefined} value
 * @return {!proto.vseth.sip.payment.Order} returns this
*/
proto.vseth.sip.payment.Order.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.Order.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional View view = 16;
 * @return {!proto.vseth.sip.payment.Order.View}
 */
proto.vseth.sip.payment.Order.prototype.getView = function() {
  return /** @type {!proto.vseth.sip.payment.Order.View} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.vseth.sip.payment.Order.View} value
 * @return {!proto.vseth.sip.payment.Order} returns this
 */
proto.vseth.sip.payment.Order.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.OrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.OrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.OrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.OrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, ""),
    number: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    product: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemAmount: (f = msg.getItemAmount()) && vseth_type_money_pb.Money.toObject(includeInstance, f),
    vatAmount: (f = msg.getVatAmount()) && vseth_type_money_pb.Money.toObject(includeInstance, f),
    vatCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vatPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    refunded: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.OrderItem}
 */
proto.vseth.sip.payment.OrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.OrderItem;
  return proto.vseth.sip.payment.OrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.OrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.OrderItem}
 */
proto.vseth.sip.payment.OrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProduct(value);
      break;
    case 6:
      var value = new vseth_type_money_pb.Money;
      reader.readMessage(value,vseth_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setItemAmount(value);
      break;
    case 7:
      var value = new vseth_type_money_pb.Money;
      reader.readMessage(value,vseth_type_money_pb.Money.deserializeBinaryFromReader);
      msg.setVatAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVatCode(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVatPercent(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefunded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.OrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.OrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.OrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.OrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProduct();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vseth_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getVatAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vseth_type_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getVatCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVatPercent();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRefunded();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.OrderItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order = 2;
 * @return {string}
 */
proto.vseth.sip.payment.OrderItem.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 number = 3;
 * @return {number}
 */
proto.vseth.sip.payment.OrderItem.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.vseth.sip.payment.OrderItem.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product = 5;
 * @return {string}
 */
proto.vseth.sip.payment.OrderItem.prototype.getProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional vseth.type.Money item_amount = 6;
 * @return {?proto.vseth.type.Money}
 */
proto.vseth.sip.payment.OrderItem.prototype.getItemAmount = function() {
  return /** @type{?proto.vseth.type.Money} */ (
    jspb.Message.getWrapperField(this, vseth_type_money_pb.Money, 6));
};


/**
 * @param {?proto.vseth.type.Money|undefined} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
*/
proto.vseth.sip.payment.OrderItem.prototype.setItemAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.clearItemAmount = function() {
  return this.setItemAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.OrderItem.prototype.hasItemAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional vseth.type.Money vat_amount = 7;
 * @return {?proto.vseth.type.Money}
 */
proto.vseth.sip.payment.OrderItem.prototype.getVatAmount = function() {
  return /** @type{?proto.vseth.type.Money} */ (
    jspb.Message.getWrapperField(this, vseth_type_money_pb.Money, 7));
};


/**
 * @param {?proto.vseth.type.Money|undefined} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
*/
proto.vseth.sip.payment.OrderItem.prototype.setVatAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.clearVatAmount = function() {
  return this.setVatAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.OrderItem.prototype.hasVatAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string vat_code = 8;
 * @return {string}
 */
proto.vseth.sip.payment.OrderItem.prototype.getVatCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setVatCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional float vat_percent = 9;
 * @return {number}
 */
proto.vseth.sip.payment.OrderItem.prototype.getVatPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setVatPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string language_code = 10;
 * @return {string}
 */
proto.vseth.sip.payment.OrderItem.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool refunded = 11;
 * @return {boolean}
 */
proto.vseth.sip.payment.OrderItem.prototype.getRefunded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.OrderItem} returns this
 */
proto.vseth.sip.payment.OrderItem.prototype.setRefunded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.vseth.sip.payment.Order.Filter.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    view: jspb.Message.getFieldWithDefault(msg, 4, 0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    refreshGatewayStatus: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.ListOrdersRequest}
 */
proto.vseth.sip.payment.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.ListOrdersRequest;
  return proto.vseth.sip.payment.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.ListOrdersRequest}
 */
proto.vseth.sip.payment.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.Order.Filter;
      reader.readMessage(value,proto.vseth.sip.payment.Order.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {!proto.vseth.sip.payment.Order.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshGatewayStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vseth.sip.payment.Order.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRefreshGatewayStatus();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional Order.Filter filter = 1;
 * @return {?proto.vseth.sip.payment.Order.Filter}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.getFilter = function() {
  return /** @type{?proto.vseth.sip.payment.Order.Filter} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.Order.Filter, 1));
};


/**
 * @param {?proto.vseth.sip.payment.Order.Filter|undefined} value
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
*/
proto.vseth.sip.payment.ListOrdersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Order.View view = 4;
 * @return {!proto.vseth.sip.payment.Order.View}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.getView = function() {
  return /** @type {!proto.vseth.sip.payment.Order.View} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vseth.sip.payment.Order.View} value
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string language_code = 5;
 * @return {string}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool refresh_gateway_status = 6;
 * @return {boolean}
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.getRefreshGatewayStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.ListOrdersRequest} returns this
 */
proto.vseth.sip.payment.ListOrdersRequest.prototype.setRefreshGatewayStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.payment.ListOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.ListOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.ListOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.vseth.sip.payment.Order.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.ListOrdersResponse}
 */
proto.vseth.sip.payment.ListOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.ListOrdersResponse;
  return proto.vseth.sip.payment.ListOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.ListOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.ListOrdersResponse}
 */
proto.vseth.sip.payment.ListOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.Order;
      reader.readMessage(value,proto.vseth.sip.payment.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.ListOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.ListOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.payment.Order.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated Order orders = 1;
 * @return {!Array<!proto.vseth.sip.payment.Order>}
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vseth.sip.payment.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.payment.Order, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.payment.Order>} value
 * @return {!proto.vseth.sip.payment.ListOrdersResponse} returns this
*/
proto.vseth.sip.payment.ListOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.payment.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.Order}
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.payment.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.ListOrdersResponse} returns this
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.ListOrdersResponse} returns this
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.payment.ListOrdersResponse} returns this
 */
proto.vseth.sip.payment.ListOrdersResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto.vseth.sip.payment.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.CreateOrderRequest}
 */
proto.vseth.sip.payment.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.CreateOrderRequest;
  return proto.vseth.sip.payment.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.CreateOrderRequest}
 */
proto.vseth.sip.payment.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.Order;
      reader.readMessage(value,proto.vseth.sip.payment.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vseth.sip.payment.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.vseth.sip.payment.Order}
 */
proto.vseth.sip.payment.CreateOrderRequest.prototype.getOrder = function() {
  return /** @type{?proto.vseth.sip.payment.Order} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.Order, 1));
};


/**
 * @param {?proto.vseth.sip.payment.Order|undefined} value
 * @return {!proto.vseth.sip.payment.CreateOrderRequest} returns this
*/
proto.vseth.sip.payment.CreateOrderRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.CreateOrderRequest} returns this
 */
proto.vseth.sip.payment.CreateOrderRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.CreateOrderRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.GetOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.GetOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.GetOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    view: jspb.Message.getFieldWithDefault(msg, 2, 0),
    languageCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    claim: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.GetOrderRequest}
 */
proto.vseth.sip.payment.GetOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.GetOrderRequest;
  return proto.vseth.sip.payment.GetOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.GetOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.GetOrderRequest}
 */
proto.vseth.sip.payment.GetOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.vseth.sip.payment.Order.View} */ (reader.readEnum());
      msg.setView(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguageCode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClaim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.GetOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.GetOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.GetOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getView();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLanguageCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClaim();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.GetOrderRequest} returns this
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Order.View view = 2;
 * @return {!proto.vseth.sip.payment.Order.View}
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.getView = function() {
  return /** @type {!proto.vseth.sip.payment.Order.View} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vseth.sip.payment.Order.View} value
 * @return {!proto.vseth.sip.payment.GetOrderRequest} returns this
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.setView = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string language_code = 3;
 * @return {string}
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.getLanguageCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.GetOrderRequest} returns this
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.setLanguageCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool claim = 4;
 * @return {boolean}
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.getClaim = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.GetOrderRequest} returns this
 */
proto.vseth.sip.payment.GetOrderRequest.prototype.setClaim = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.RefundOrderItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.RefundOrderItemsRequest}
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.RefundOrderItemsRequest;
  return proto.vseth.sip.payment.RefundOrderItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.RefundOrderItemsRequest}
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.RefundOrderItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.RefundOrderItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string name = 1;
 * @return {!Array<string>}
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vseth.sip.payment.RefundOrderItemsRequest} returns this
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.RefundOrderItemsRequest} returns this
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.RefundOrderItemsRequest} returns this
 */
proto.vseth.sip.payment.RefundOrderItemsRequest.prototype.clearNameList = function() {
  return this.setNameList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.RefundOrderItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.RefundOrderItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.vseth.sip.payment.OrderItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.RefundOrderItemsResponse}
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.RefundOrderItemsResponse;
  return proto.vseth.sip.payment.RefundOrderItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.RefundOrderItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.RefundOrderItemsResponse}
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.OrderItem;
      reader.readMessage(value,proto.vseth.sip.payment.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.RefundOrderItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.RefundOrderItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.payment.OrderItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderItem items = 1;
 * @return {!Array<!proto.vseth.sip.payment.OrderItem>}
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.vseth.sip.payment.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.payment.OrderItem, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.payment.OrderItem>} value
 * @return {!proto.vseth.sip.payment.RefundOrderItemsResponse} returns this
*/
proto.vseth.sip.payment.RefundOrderItemsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.payment.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.OrderItem}
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.payment.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.RefundOrderItemsResponse} returns this
 */
proto.vseth.sip.payment.RefundOrderItemsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.PaymentGateway.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.PaymentGateway} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.PaymentGateway.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    method: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expireTime: (f = msg.getExpireTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paidTime: (f = msg.getPaidTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.PaymentGateway}
 */
proto.vseth.sip.payment.PaymentGateway.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.PaymentGateway;
  return proto.vseth.sip.payment.PaymentGateway.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.PaymentGateway} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.PaymentGateway}
 */
proto.vseth.sip.payment.PaymentGateway.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {!proto.vseth.sip.payment.PaymentGateway.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.vseth.sip.payment.PaymentMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaidTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.PaymentGateway.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.PaymentGateway} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.PaymentGateway.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpireTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaidTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vseth.sip.payment.PaymentGateway.Status = {
  STATUS_UNSPECIFIED: 0,
  OPEN: 1,
  PAID: 2,
  EXPIRED: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.PaymentGateway.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.PaymentGateway.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    method: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createTime: (f = msg.getCreateTime()) && proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.toObject(includeInstance, f),
    expireTime: (f = msg.getExpireTime()) && proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.toObject(includeInstance, f),
    paidTime: (f = msg.getPaidTime()) && proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.toObject(includeInstance, f),
    sortBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
    order: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.PaymentGateway.Filter;
  return proto.vseth.sip.payment.PaymentGateway.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.vseth.sip.payment.PaymentGateway.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.vseth.sip.payment.PaymentMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 4:
      var value = new proto.vseth.sip.payment.PaymentGateway.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 5:
      var value = new proto.vseth.sip.payment.PaymentGateway.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinaryFromReader);
      msg.setExpireTime(value);
      break;
    case 6:
      var value = new proto.vseth.sip.payment.PaymentGateway.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 7:
      var value = new proto.vseth.sip.payment.PaymentGateway.Filter.DateRange;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinaryFromReader);
      msg.setPaidTime(value);
      break;
    case 8:
      var value = /** @type {!proto.vseth.sip.payment.PaymentGateway.Filter.SortBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.PaymentGateway.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.PaymentGateway.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getExpireTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getPaidTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.SortBy = {
  NAME: 0,
  STATUS: 1,
  CREATED_TIME: 2,
  EXPIRE_TIME: 3,
  UPDATE_TIME: 4,
  PAID_TIME: 5
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_ = [[1,2],[3,4]];

/**
 * @enum {number}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.FromCase = {
  FROM_NOT_SET: 0,
  UNLIMITED_FROM_RANGE: 1,
  TIMESTAMP_FROM: 2
};

/**
 * @return {proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.FromCase}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.getFromCase = function() {
  return /** @type {proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.FromCase} */(jspb.Message.computeOneofCase(this, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.UntilCase = {
  UNTIL_NOT_SET: 0,
  UNLIMITED_UNTIL_RANGE: 3,
  TIMESTAMP_UNTIL: 4
};

/**
 * @return {proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.UntilCase}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.getUntilCase = function() {
  return /** @type {proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.UntilCase} */(jspb.Message.computeOneofCase(this, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    unlimitedFromRange: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    timestampFrom: (f = msg.getTimestampFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unlimitedUntilRange: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    timestampUntil: (f = msg.getTimestampUntil()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.PaymentGateway.Filter.DateRange;
  return proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlimitedFromRange(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampFrom(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnlimitedUntilRange(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTimestampFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTimestampUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool unlimited_from_range = 1;
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.getUnlimitedFromRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.setUnlimitedFromRange = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.clearUnlimitedFromRange = function() {
  return jspb.Message.setOneofField(this, 1, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.hasUnlimitedFromRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.getTimestampFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
*/
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.setTimestampFrom = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.clearTimestampFrom = function() {
  return this.setTimestampFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.hasTimestampFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool unlimited_until_range = 3;
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.getUnlimitedUntilRange = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.setUnlimitedUntilRange = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.clearUnlimitedUntilRange = function() {
  return jspb.Message.setOneofField(this, 3, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.hasUnlimitedUntilRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_until = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.getTimestampUntil = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
*/
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.setTimestampUntil = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.clearTimestampUntil = function() {
  return this.setTimestampUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.DateRange.prototype.hasTimestampUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.vseth.sip.payment.PaymentGateway.Status}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.payment.PaymentGateway.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vseth.sip.payment.PaymentGateway.Status} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PaymentMethod method = 3;
 * @return {!proto.vseth.sip.payment.PaymentMethod}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getMethod = function() {
  return /** @type {!proto.vseth.sip.payment.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vseth.sip.payment.PaymentMethod} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DateRange create_time = 4;
 * @return {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getCreateTime = function() {
  return /** @type{?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange, 4));
};


/**
 * @param {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
*/
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DateRange expire_time = 5;
 * @return {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getExpireTime = function() {
  return /** @type{?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange, 5));
};


/**
 * @param {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
*/
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setExpireTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.clearExpireTime = function() {
  return this.setExpireTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.hasExpireTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DateRange update_time = 6;
 * @return {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getUpdateTime = function() {
  return /** @type{?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange, 6));
};


/**
 * @param {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
*/
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DateRange paid_time = 7;
 * @return {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getPaidTime = function() {
  return /** @type{?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.PaymentGateway.Filter.DateRange, 7));
};


/**
 * @param {?proto.vseth.sip.payment.PaymentGateway.Filter.DateRange|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
*/
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setPaidTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.clearPaidTime = function() {
  return this.setPaidTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.hasPaidTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SortBy sort_by = 8;
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter.SortBy}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getSortBy = function() {
  return /** @type {!proto.vseth.sip.payment.PaymentGateway.Filter.SortBy} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vseth.sip.payment.PaymentGateway.Filter.SortBy} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string order = 9;
 * @return {string}
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.PaymentGateway.Filter} returns this
 */
proto.vseth.sip.payment.PaymentGateway.Filter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order = 2;
 * @return {string}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.vseth.sip.payment.PaymentGateway.Status}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getStatus = function() {
  return /** @type {!proto.vseth.sip.payment.PaymentGateway.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vseth.sip.payment.PaymentGateway.Status} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PaymentMethod method = 4;
 * @return {!proto.vseth.sip.payment.PaymentMethod}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getMethod = function() {
  return /** @type {!proto.vseth.sip.payment.PaymentMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vseth.sip.payment.PaymentMethod} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
*/
proto.vseth.sip.payment.PaymentGateway.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp expire_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getExpireTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
*/
proto.vseth.sip.payment.PaymentGateway.prototype.setExpireTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.clearExpireTime = function() {
  return this.setExpireTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.hasExpireTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
*/
proto.vseth.sip.payment.PaymentGateway.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp paid_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getPaidTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
*/
proto.vseth.sip.payment.PaymentGateway.prototype.setPaidTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.clearPaidTime = function() {
  return this.setPaidTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.hasPaidTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string url = 9;
 * @return {string}
 */
proto.vseth.sip.payment.PaymentGateway.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.PaymentGateway} returns this
 */
proto.vseth.sip.payment.PaymentGateway.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.ListPaymentGatewaysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.vseth.sip.payment.PaymentGateway.Filter.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    refreshStatus: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.ListPaymentGatewaysRequest;
  return proto.vseth.sip.payment.ListPaymentGatewaysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.PaymentGateway.Filter;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.ListPaymentGatewaysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vseth.sip.payment.PaymentGateway.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRefreshStatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional PaymentGateway.Filter filter = 1;
 * @return {?proto.vseth.sip.payment.PaymentGateway.Filter}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.getFilter = function() {
  return /** @type{?proto.vseth.sip.payment.PaymentGateway.Filter} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.PaymentGateway.Filter, 1));
};


/**
 * @param {?proto.vseth.sip.payment.PaymentGateway.Filter|undefined} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} returns this
*/
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool refresh_status = 4;
 * @return {boolean}
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.getRefreshStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysRequest} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysRequest.prototype.setRefreshStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.ListPaymentGatewaysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gatewaysList: jspb.Message.toObjectList(msg.getGatewaysList(),
    proto.vseth.sip.payment.PaymentGateway.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysResponse}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.ListPaymentGatewaysResponse;
  return proto.vseth.sip.payment.ListPaymentGatewaysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysResponse}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.PaymentGateway;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.deserializeBinaryFromReader);
      msg.addGateways(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.ListPaymentGatewaysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGatewaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vseth.sip.payment.PaymentGateway.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated PaymentGateway gateways = 1;
 * @return {!Array<!proto.vseth.sip.payment.PaymentGateway>}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.getGatewaysList = function() {
  return /** @type{!Array<!proto.vseth.sip.payment.PaymentGateway>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vseth.sip.payment.PaymentGateway, 1));
};


/**
 * @param {!Array<!proto.vseth.sip.payment.PaymentGateway>} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} returns this
*/
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.setGatewaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vseth.sip.payment.PaymentGateway=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vseth.sip.payment.PaymentGateway}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.addGateways = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vseth.sip.payment.PaymentGateway, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.clearGatewaysList = function() {
  return this.setGatewaysList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_size = 3;
 * @return {number}
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vseth.sip.payment.ListPaymentGatewaysResponse} returns this
 */
proto.vseth.sip.payment.ListPaymentGatewaysResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.CreatePaymentGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    paymentGateway: (f = msg.getPaymentGateway()) && proto.vseth.sip.payment.PaymentGateway.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.CreatePaymentGatewayRequest}
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.CreatePaymentGatewayRequest;
  return proto.vseth.sip.payment.CreatePaymentGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.CreatePaymentGatewayRequest}
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vseth.sip.payment.PaymentGateway;
      reader.readMessage(value,proto.vseth.sip.payment.PaymentGateway.deserializeBinaryFromReader);
      msg.setPaymentGateway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.CreatePaymentGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaymentGateway();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vseth.sip.payment.PaymentGateway.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaymentGateway payment_gateway = 1;
 * @return {?proto.vseth.sip.payment.PaymentGateway}
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.prototype.getPaymentGateway = function() {
  return /** @type{?proto.vseth.sip.payment.PaymentGateway} */ (
    jspb.Message.getWrapperField(this, proto.vseth.sip.payment.PaymentGateway, 1));
};


/**
 * @param {?proto.vseth.sip.payment.PaymentGateway|undefined} value
 * @return {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} returns this
*/
proto.vseth.sip.payment.CreatePaymentGatewayRequest.prototype.setPaymentGateway = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vseth.sip.payment.CreatePaymentGatewayRequest} returns this
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.prototype.clearPaymentGateway = function() {
  return this.setPaymentGateway(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vseth.sip.payment.CreatePaymentGatewayRequest.prototype.hasPaymentGateway = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vseth.sip.payment.GetPaymentGatewayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vseth.sip.payment.GetPaymentGatewayRequest}
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vseth.sip.payment.GetPaymentGatewayRequest;
  return proto.vseth.sip.payment.GetPaymentGatewayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vseth.sip.payment.GetPaymentGatewayRequest}
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vseth.sip.payment.GetPaymentGatewayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vseth.sip.payment.GetPaymentGatewayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vseth.sip.payment.GetPaymentGatewayRequest} returns this
 */
proto.vseth.sip.payment.GetPaymentGatewayRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.vseth.sip.payment.PaymentMethod = {
  PAYMENT_METHOD_UNSPECIFIED: 0,
  GUTHABEN: 1,
  PAYREXX: 10,
  DUMMY: 99
};

goog.object.extend(exports, proto.vseth.sip.payment);
