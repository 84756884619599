import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Order,
    fetchOrder,
    selectOrder,
    selectError,
    selectIsLoading,
    selectPaymentGatewayRedirect,
    OrderStatus,
    orderNamePrefix,
} from "../features/payment/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadScreen from "../components/LoadScreen";
import ErrorScreen from "../components/ErrorScreen";
import { selectIsAuthenticated, selectTokens } from "../features/auth/authSlice";
import { Checkout } from "../components/Checkout";
import RedirectScreen from "../components/RedirectScreen";
import { Container, LoadingOverlay, Skeleton } from "@mantine/core";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../app/store";


// OrderPage renders a order in all of its possible state together with buttons to pay it if applicable
const OrderPage: React.FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const order = useSelector(selectOrder);
    const redirectURL = useSelector(selectPaymentGatewayRedirect);
    const isLoading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const tokensDebug = useSelector(selectTokens);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isAuthenticated || isLoading || id === undefined) {
            return;
        } else {
            dispatch(fetchOrder(orderNamePrefix + id));
        }
    }, [dispatch, id, isAuthenticated]);

    // Redirect when gateway url is ready
    if (redirectURL) {
        return <RedirectScreen to={redirectURL} />;
    }

    if (isLoading) {
        return (
            <LoadScreen
                header={t("OrderPage.wait")}
                body={t("OrderPage.orderPreparing")}
            />
        );
    }

    if (!isAuthenticated) {
        return <LoadingOverlay visible={!isAuthenticated} overlayBlur={2}>
            <Skeleton animate={false} height={8} mt={6} radius="xl" />
            <Skeleton animate={false} height={8} mt={6} radius="xl" />
            <Skeleton animate={false} height={8} mt={6} radius="xl" />
        </LoadingOverlay>;
    }

    if (error || !order) {
        return <ErrorScreen error={error} />;
    }

    switch (order.status) {
        case OrderStatus.OPEN:
            return <OrderOpen order={order} />;
        case OrderStatus.PAID:
            return (
                <RedirectScreen
                    to={order.callbackUrl}
                    explanation={t("OrderPage.paidExplanation", {
                        reference: order.number,
                    })}
                />
            );
        case OrderStatus.EXPIRED:
            return <OrderExpired order={order} />;
        default:
            console.log("error", "Unknown order status" + order.status);
            return <ErrorScreen error={error} />;
    }
};

interface PropsWithOrder {
    order: Order;
}

const OrderOpen: React.FC<PropsWithOrder> = ({ order }) => {
    return (
        <Container>
            <Checkout order={order} />
        </Container>
    );
};

const OrderExpired: React.FC<PropsWithOrder> = ({ order }) => {
    const { t } = useTranslation();

    return (
        <div
            className="d-flex flex-column justify-content-center text-center"
            style={{ height: "80vh" }}
        >
            <h1>{t("OrderPage.expired")}</h1>
            <p>{t("OrderPage.orderExpired", { reference: order.number })}</p>
        </div>
    );
};

export default OrderPage;
