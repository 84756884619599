import React from "react";

 
interface ErrorScreenProps {
    error?: Error
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => (
    <div
        className="d-flex flex-column justify-content-center text-center"
        style={{ height: "80vh" }}
    >
        <h1>An error occured.</h1>
        <p>
            Try reloading the page and contact the administrator if the error
            persists.
        </p>
        {error && JSON.stringify(error)}
    </div>
);

export default ErrorScreen;
