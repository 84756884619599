import React from "react";
import ReactDOM from "react-dom";
import { createRoot, Root } from 'react-dom/client';
import { Provider } from "react-redux";

import App from "./app/App"

import store from "./app/store";

import * as serviceWorker from "./serviceWorker";
import "./locals/i18n";


const root = createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

/*
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', () => render(root))
} */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
