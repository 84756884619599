import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../app/store"; // Adjust the import path as necessary
import {
    fetchOrder,
    Order,
    selectOrderItems,
    selectOrderTotal,
} from "../features/payment/paymentSlice";
import { getFrancs } from "../util/proto";
import { GatewayButtons } from "./GatewayButtons";
import { OrderItemsTable } from "./OrderItemsTable";
import { Button, Card, Container, Group } from "@mantine/core";

interface Props {
    order: Order;
}

// Checkout renders the order together with buttons to pay it
export const Checkout: React.FC<Props> = ({ order }) => {
    const orderItems = useSelector(selectOrderItems);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    return (
        <Container>
            <h2>
                {t("Checkout.orderWithNumber", { reference: order.number })}
            </h2>
            <Card>
                <OrderItemsTable orderItems={orderItems} />
                <Group position="apart" p="md">
                    <b>{t("Checkout.total")}</b>
                    <b>CHF {getFrancs(order.total)}</b>
                </Group>
            </Card>
            <Card>
                <h3>{t("Checkout.selectPaymentOptions")}</h3>
                <GatewayButtons />
                <p>{t("Checkout.alreadyPaidReload")}</p>
                <Button
                    onClick={() => dispatch(fetchOrder(order.name))}
                >
                    {t("Checkout.updateOrderButton")}
                </Button>
            </Card>
        </Container>
    );
};
