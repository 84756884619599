export const i18n_english = {
    translation: {
        lang: "en",
        example: {
            title: "Translations are important!",
            introduction:
                "A lot of members of ETH Zurich do not speak german, so it is very important for the IT to tools that can be easily translated into multiple languages. This template showcases how this can be achieved.",
            buttonText: "Change language",
        },
        Navbar: {
            home: "Home",
        },
        OrderPage: {
            wait: "Please wait",
            orderPreparing: "Your order is being processed.",
            paidExplanation:
                "Order #{{reference}} has been paid. You will be redirected shortly.",
            expired: "Expired",
            orderExpired:
                "Order #{{reference}} has expired and can no longer be paid.",
        },
        Checkout: {
            orderWithNumber: "Order #{{reference}}",
            total: "Total",
            selectPaymentOptions: "Select payment option",
            alreadyPaidReload:
                "You already paid this order? Update it manually with the button below.",
            updateOrderButton: "Update Order",
        },
        GatewayButtons: {
            payrexx: "Payrexx",
            guthaben: "Guthaben",
            dummy: "Dummy",
            unspecified: "Unspecified",
        },
        OrderItemsTable: {
            item: "Item",
            price: "Price [CHF]",
        },
        RedirectScreen: {
            header: "Redirecting",
            body: "You will be redirected shortly.",
            pressButtonIfFail:
                "Click the button if the automatic redirect doesn't work.",
            redirectButton: "Redirect",
        },
    },
};
