import { combineReducers } from "@reduxjs/toolkit";

import auth from "../features/auth/authSlice";
import payment from "../features/payment/paymentSlice";

/**
 * Construct and export the root reducer used in this redux application
 */

const rootReducer = combineReducers({
    auth,
    payment,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
