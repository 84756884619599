import React, { ReactNode } from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAuthStateFromToken, selectTokens, setAuthState} from "../features/auth/authSlice";
import { AuthProvider } from "../features/auth/AuthProvider";

type CustomAuthProviderProps = {
  children: ReactNode
}

export const CustomAuthProvider: React.FC<CustomAuthProviderProps> = props => {
  const tokens = useSelector(selectTokens);

  const dispatch = useDispatch();

  return (
    <AuthProvider
      setAuthState={authState => {
        dispatch(setAuthState(authState))
      }}
      authState={getAuthStateFromToken(tokens)}
    >
      {props.children}
    </AuthProvider>
  );
};
