import { Money } from "../proto/vseth/type/money_pb";

export function getAuthMetadata(token?: string) {
    return {
        authorization: `Bearer ${token}`,
    };
}

/**
 * Returns the cents of the amount (Money.AsObject) given.
 * Returns 0 if undefined. Ignores currency code as it will be CHF.
 * @param amount
 */
export const getFrancs = (amount?: Money.AsObject) => {
    if (!amount) {
        return 0;
    }
    return (amount.cents / 100).toFixed(2);
};
