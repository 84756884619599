export const i18n_german = {
    translation: {
        lang: "de",
        example: {
            title: "Translations are important!",
            introduction:
                "A lot of members of ETH Zurich do not speak german, so it is very important for the IT to tools that can be easily translated into multiple languages. This template showcases how this can be achieved.",
            buttonText: "Change language",
        },
        Navbar: {
            home: "Home",
        },
        OrderPage: {
            wait: "Bitte warten",
            orderPreparing: "Deine Bestellung wird bearbeitet.",
            paidExplanation:
                "Bestellung #{{reference}} ist bezahlt. Du wirst gleich weitergeleitet.",
            expired: "Abgelaufen",
            orderExpired:
                "Bestellung #{{reference}} ist abgelaufen und kann nicht mehr bezahlt werden.",
        },
        Checkout: {
            orderWithNumber: "Bestellung #{{reference}}",
            total: "Total",
            selectPaymentOptions: "Wähle deine Zahlungsmethode",
            alreadyPaidReload:
                "Du hast diese Bestellung bereits bezahlt? dann akualisiere sie manuell.",
            updateOrderButton: "Bestellung Aktualisieren",
        },
        GatewayButtons: {
            payrexx: "Payrexx",
            guthaben: "Guthaben",
            dummy: "Dummy",
            unspecified: "Unspecified",
        },
        OrderItemsTable: {
            item: "Position",
            price: "Preis [CHF]",
        },
        RedirectScreen: {
            header: "Weiterleiten",
            body: "Du wirst gleich weitergeleitet.",
            pressButtonIfFail:
                "Clicke auf den unteren Knopf, falls die automatische Weiterleitung nicht funktioniert.",
            redirectButton: "Weiterleiten",
        },
    },
};
