import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectAccessToken } from "../auth/authSlice";
import {
    CreatePaymentGatewayRequest,
    GetOrderRequest,
    Order as OrderMessage,
    OrderItem as OrderItemMessage,
    PaymentGateway as PaymentGatewayMessage,
    PaymentMethod,
} from "../../proto/vseth/sip/payment/payment_pb";
import { getAuthMetadata } from "../../util/proto";
import { AppThunk } from "../../app/store";

export const orderNamePrefix = "orders/";

export type Order = OrderMessage.AsObject;
export type OrderItem = OrderItemMessage.AsObject;
export type PaymentGateway = PaymentGatewayMessage.AsObject;

export const OrderStatus = OrderMessage.Status;

interface PaymentState {
    order: Order | undefined;
    gateway: PaymentGateway | undefined;
    isLoading: boolean;
    error: Error | undefined;
}

export const initialState: PaymentState = {
    order: undefined,
    gateway: undefined,
    isLoading: false,
    error: undefined,
};

const payment = createSlice({
    name: "payment",
    initialState,
    reducers: {
        fetchOrderStart: (state) => {
            state.isLoading = true;
        },
        fetchOrderSuccess: (state, { payload }: PayloadAction<Order>) => {
            state.order = payload;
            state.isLoading = false;
        },
        fetchOrderFailure: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload;
            state.isLoading = false;
        },
        createGatewayStart: (state) => {
            state.isLoading = true;
        },
        createGatewaySuccess: (
            state,
            { payload }: PayloadAction<PaymentGateway>
        ) => {
            state.isLoading = false;
            state.gateway = payload;
        },
        createGatewayFailure: (state, { payload }: PayloadAction<Error>) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

const {
    fetchOrderStart,
    fetchOrderSuccess,
    fetchOrderFailure,
    createGatewayStart,
    createGatewaySuccess,
    createGatewayFailure,
} = payment.actions;

export default payment.reducer;

// Actions
/**
 * fetches the order given and claims it if not already claimed
 */
export const fetchOrder = (orderName: string): AppThunk => async (
    dispatch,
    getState,
    paymentClient
) => {
    dispatch(fetchOrderStart());
    const token = selectAccessToken(getState());

    const request = new GetOrderRequest();
    request.setName(orderName);
    request.setView(OrderMessage.View.FULL);
    return paymentClient
        .getOrder(request, getAuthMetadata(token))
        .then((response) => {
            // if not claimed, request again and claim
            if (response.getStatus() === OrderStatus.CREATED) {
                request.setClaim(true);
                return paymentClient
                    .getOrder(request, getAuthMetadata(token))
                    .then((response) => {
                        dispatch(fetchOrderSuccess(response.toObject()));
                    })
                    .catch((err) => {
                        dispatch(fetchOrderFailure(err));
                    });
            }
            dispatch(fetchOrderSuccess(response.toObject()));
        })
        .catch((err) => {
            dispatch(fetchOrderFailure(err));
        });
};

/**
 * creates a new payment gateway for the currently stored order and the method specified
 */
export const createGateway = (method: PaymentMethod): AppThunk => async (
    dispatch,
    getState,
    paymentClient
) => {
    dispatch(createGatewayStart());
    const state = getState();
    const token = selectAccessToken(state);
    const orderName = selectOrderName(state);

    const paymentGateway = new PaymentGatewayMessage();
    paymentGateway.setOrder(orderName);
    paymentGateway.setMethod(method);
    const request = new CreatePaymentGatewayRequest();
    request.setPaymentGateway(paymentGateway);

    return paymentClient
        .createPaymentGateway(request, getAuthMetadata(token))
        .then((response) => {
            dispatch(createGatewaySuccess(response.toObject()));
        })
        .catch((err) => {
            dispatch(createGatewayFailure(err));
        });
};

// selectors
type PaymentSliceRoot = {
    payment: ReturnType<typeof payment.reducer>;
};

/**
 * The currently fetched order.
 * Empty string if undefined.
 * @param state
 */
export const selectOrderName = (state: PaymentSliceRoot) =>
    state.payment.order?.name ? state.payment.order.name : "";

/**
 * The currently fetched order.
 * @param state
 */
export const selectOrder = (state: PaymentSliceRoot) => state.payment.order;

/**
 * The order items of the currently fetched order.
 * Empty array if order undefined.
 * @param state
 */
export const selectOrderItems = (state: PaymentSliceRoot) =>
    state.payment.order?.itemsList ? state.payment.order.itemsList : [];

/**
 * The payment methods of the currently fetched order.
 * Empty array if order undefined.
 * @param state
 */
export const selectPaymentMethods = (state: PaymentSliceRoot) =>
    state.payment.order?.paymentMethodsList
        ? state.payment.order.paymentMethodsList
        : [];

/**
 * The payment gateway redirect url of the currently set payment gateway.
 * Empty string if payment gateway undefined.
 * @param state
 */
export const selectPaymentGatewayRedirect = (state: PaymentSliceRoot) =>
    state.payment.gateway?.url ? state.payment.gateway.url : "";

/**
 * Cents of the total of the currently fetched order.
 * 0 if order undefined.
 * @param state
 */
export const selectOrderTotal = (state: PaymentSliceRoot) =>
    state.payment.order?.total;

// Selectors for meta info of this state
export const selectIsLoading = (state: PaymentSliceRoot) =>
    state.payment.isLoading;
export const selectError = (state: PaymentSliceRoot) => state.payment.error;
