import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { jwtDecode } from "jwt-decode";

export interface AuthState {
  isAuthenticated: boolean;
  tokens?: any;
  userInfo?: {
    azp: string;
    exp: number;
    prefered_username: string;
    family_name: string;
    given_name: string;
    name: string;
    resource_access: {
      [id: string]: {
        roles: string[];
      };
    };
  };
}

/**
 * Extracts the infomration of an auth Token and creates an AuthState
 * @param tokens Keycloak tokens
 */
export const getAuthStateFromToken = (
  tokens: { idToken: string, refreshToken: string, token: string } | null = {
    idToken: "",
    refreshToken: "",
    token: ""
  }
): AuthState => {
  const decodedJwt =
    tokens && tokens.idToken ? (jwtDecode(tokens.idToken) as any) : {};
  const authState = {
    isAuthenticated: !!(tokens && tokens.token),
    tokens,
    userInfo: {
      resource_access: {},
      azp: "",
      exp: 0,
      preferred_username: "",
      family_name: "",
      given_name: "",
      name: "",
      ...decodedJwt
    }
  }

  return authState;
}


const initialState: AuthState = getAuthStateFromToken(null) as AuthState;

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, { payload }: PayloadAction<AuthState>) {
            return payload;
        },
    },
});

export const { setAuthState } = auth.actions;

export default auth.reducer;

// selectors
type AuthSliceRoot = {
    auth: ReturnType<typeof auth.reducer>;
};

export const selectTokens = (state: AuthSliceRoot) => state.auth.tokens;
export const selectUserInfo = (state: AuthSliceRoot) => state.auth.userInfo;
export const selectIsAuthenticated = (state: AuthSliceRoot) =>
    state.auth.isAuthenticated;

export const selectAccessToken = createSelector([selectTokens], (tokens) => {
    if (tokens) {
        return tokens.token;
    }
    return undefined;
});

const clientId = "payment-checkout";

export const selectUserRoles = createSelector([selectUserInfo], (userInfo) => {
    if (
        userInfo &&
        userInfo.resource_access &&
        userInfo.resource_access[clientId]
    ) {
        return userInfo.resource_access[clientId].roles;
    }
    return [] as string[];
});


