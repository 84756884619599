import React, { ReactNode } from "react";
import "./App.css";
import { createBrowserRouter, createRoutesFromElements, Link, Outlet, Route, RouterProvider } from "react-router-dom";
import "@vseth/vseth-theme/dist/vseth-bootstrap-theme.css";
import { CustomAuthProvider } from "../components/CustomAuthProvider";
import OrderPage from "../pages/OrderPage";
import { useTranslation } from "react-i18next";
import { makeVsethTheme, VSETHExternalApp, VSETHThemeProvider } from "vseth-canine-ui";
import ErrorScreen from "../components/ErrorScreen";
import { MantineProvider } from "@mantine/core";


const AppLayout: React.FC = () => {
    const { i18n } = useTranslation();

    const theme = makeVsethTheme();
    theme.globalStyles = (theme) => ({
        'html': {
            fontSize: "1.15rem"
        }
    });

    return (
        <MantineProvider>
            <CustomAuthProvider>
                <React.StrictMode>
                    <VSETHThemeProvider theme={theme}>
                        <VSETHExternalApp
                            title="Checkout"
                            appNav={[]}
                            makeWrapper={(url: string | undefined, child: ReactNode) => (
                                <Link to={url ? url : ""} style={{ textDecoration: "none", color: "inherit" }} >
                                    {child}
                                </Link>
                            )}
                            selectedLanguage={i18n.language}
                            onLanguageSelect={(lang) => i18n.changeLanguage(lang)}
                            languages={[{ key: "de", label: "Deutsch" }, { key: "en", label: "English" }]}
                        >
                            <Outlet />
                        </VSETHExternalApp>
                    </VSETHThemeProvider>
                </React.StrictMode>
            </CustomAuthProvider >
        </MantineProvider>
    );
};


/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */
const App: React.FC = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path="/"
                element={<AppLayout />}
                errorElement={<ErrorScreen error={Error("route-error")} />}
            >
                <Route
                    path="/orders/:id"
                    element={<OrderPage />}
                />
            </Route>
        )
    );

    return (
        <RouterProvider router={router} />
    );
};

export default App;
