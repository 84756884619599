import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducer";
import { PaymentPromiseClient } from "../proto/vseth/sip/payment/payment_grpc_web_pb";

/**
 * Construct the redux store used in the application.
 */

const client = new PaymentPromiseClient(
  //  "http://localhost:8080",
  "https://payment.web.api.staging-sip.ethz.ch",
  {},
  {}
);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: client,
    },
  }),
});

if (process.env.NOVE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer.ts", () => {
    const newRootReducer = require("./rootReducer.ts").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<
  void,
  RootState,
  PaymentPromiseClient,
  Action<string>
>;

export default store;
